import React,{useRef} from 'react';
import Slideshow from './slider/slider.js'
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com';
import { SiTicktick } from "react-icons/si";

export default function Body(props){

const [ok,setOk] = React.useState(false)

const [fsubmit,fsubmitSetter] = React.useState(false);
//const recaptcha = useRef(null);

function successCaptcha(){

setOk(true);
}

const sendEmail = (e) => {
   e.preventDefault();
   setOk(false);
   emailjs.sendForm('service_a5bjicq', 'template_uifoije', e.target,'-Y90Q0_8JhL5xeBpb')
      .then((result) => {
      		//console.log("success");
      		fsubmitSetter(true);
          //window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
      }, (error) => {
          console.log(error.text);
          setOk(true);

      });

  };

	return(
		<>
		<div className = "slideshow-div"> 
		<Slideshow img1 = {props.img1} img2 = {props.img2} img3 = {props.img4} img4 = {props.img5} img5 = {props.img6} img6 = {props.img7} />

		</div>
		<div className = "Promise">
		<h1> Assured Quality</h1><h1>Assured Delivery </h1>
		<p> Over 20 years of experience </p>
		</div>
		<div className = "contact-us-div">
			<div className = "contact-us-image" style = {{'backgroundImage':`url(${props.img3})`,backgroundSize: 'cover'}}>
				<h1> Contact Us </h1>
				<p> For Enquires Regarding Automotive Gears, Agricultural Gears, Differential Gears, Transmision Gear, Crown Wheel & Pinions, Axle Shafts.... </p>
			</div>
			
			{!fsubmit && (<form className = "form-container" onSubmit={e=>sendEmail(e)}>
								 
								<div className = "forMobile"> <h1>Contact us</h1> </div>

                                <input type="text" name = "fromName" placeholder="Name" required />
                            
                            
                                <input type="text" name = "fromDesignation" placeholder="Designation" />
                            
                                <input type="text" name = "fromCompany" placeholder="Company" required/>
                           
                                <input type="email" name = "fromEmail" placeholder="Email" required/>
                            
                            <textarea name="fromMessage" placeholder="Type your message here"></textarea>
                            <div className = "recaptcha">
                            <ReCAPTCHA onChange = {e=>{successCaptcha()}} sitekey= "6LfWen0qAAAAAFLhg-bXq2e201Ia7Orb73wLLUCK"/>
                            </div>
                            <button disabled = {!ok} type = "submit"> SUBMIT</button>
            		
			</form>)}
			{
				fsubmit && (<div className = "formSent"> <SiTicktick/> <h2> Response recieved </h2> </div>)
			}

		</div>
		</>

	);
}